<template>
<div>
    <div class="card-body p-0 w-100">
        <div class="form-group  p-3 border-light border p-2 bg-white rounded-lg" v-if="!loadData">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10">
                    <form @submit.prevent="getAnnouncements(page)" class="form-group icon-input mb-1">
                        <input type="text" v-model="search" style="width: 100%" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-700 fw-600 bg-grey" placeholder="Search..">
                        <button type="submit" class="border-0"><i class="ti-search font-xs text-grey-700"></i></button>
                    </form>
                </div>
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                    <router-link :to="{name: 'AnnouncementCoursesCreateAdmin',params: {idCourse: paramsId}}"  class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-0 border-0 text-uppercase "><i style="margin-right: 5px;" class="fas fa-plus"></i></router-link>
                </div>
            </div>
            <hr>
            <div v-if="exist" class="row justify-content-center">
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                    <div v-for="(item, index) in announcements.data" :key="index" class="card w-100 p-3 bg-grey p-lg-2 border-0 mb-3 rounded-lg d-block float-left">
                        <router-link :to="'/admin/courses/'+paramsId+'/announcements/'+item.id" class="float-left mb-0 text-grey-900 fw-700 m-2">{{item.title | truncate(30, '...')}}</router-link>
                        <router-link :to="'/admin/courses/'+paramsId+'/announcements/'+item.id+'/edit'" class="btn float-right mt-0" style="border-radius: 50%">
                            <i class="fas fa-edit"></i>
                        </router-link>
                        <button @click="deleteAlert(item.id)" type="button" class="btn float-right mt-0" style="border-radius: 50%">
                            <i class="fas fa-trash"></i>
                        </button>
                        <div class="row float-right mt-5">
                            <span class="font-xssss fw-600 text-grey-500 d-block mr-1 text-right">Posted On : </span>
                            <small class="ml-1 text-right">{{item.created_at | formatDate}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 my-4">
                    <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                        <li v-for="(link, index) in announcements.links" :key="index" class="page-item m-1">
                            <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevCourse()"><i class="ti-angle-left"></i></button>
                            <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextCourse()"><i class="ti-angle-right"></i></button>
                            <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changeCourse(link.label)">{{link.label}}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
        </div>
        <div class="form-group mt-3 p-3 border-light border p-2 bg-white rounded-lg" v-else>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: "Anouncements",
    data(){
        return {
            announcements: {},
            paramsId: this.$route.params.idCourse,
            exist: true,
            loadData: true,
            page: 1,
            search: ''
        }
    },
    created(){
        this.getAnnouncements(this.page)
    },
    methods: {
        async getAnnouncements(page){
            this.exist = true
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/announcement?course_id=${this.paramsId}&page=${page}&search=${this.search}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.loadData = false
                if (res.data.data.total == 0) {
                    this.exist = false
                } else {
                    this.exist = true
                    this.announcements = res.data
                }
            }).catch((err) => {
                this.loadData = false
                if (err.response.status === 404) {
                     this.exist = false
                }
            })
        },
        changeCourse(page) {
            this.page = parseInt(page)
            this.getAnnouncements(this.page)
        },
        prevCourse() {
            if (this.page >= 1) {
                this.page -= 1
                this.getAnnouncements(this.page)
            }
        },
        nextCourse() {
            if (this.announcements.last_page > this.page) {
                this.page += 1
                this.getAnnouncements(this.page)
            }
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/announcement`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Assignments - Achievement !',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.page = 1;
                        this.getAnnouncements(this.page);
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Assignments - Achievement !',
                                text: 'Failed Delete Announcement',
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
</script>
